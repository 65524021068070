import React from "react";

const RightPanel = (props) => {
   return (<>
          <ins className="adsbygoogle"
               style={{display:"block"}}
               data-ad-client="ca-pub-7221946839680198"
               data-ad-slot="3219150504"
               data-ad-format="auto"
               data-full-width-responsive="true">
          </ins>
  </>);
};


export default RightPanel;