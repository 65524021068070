import React from "react";
import { Link } from "gatsby";
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import { Toolbar } from "@mui/material";

const Footer = (props) => {
    const drawerWidth = props.drawerWidth;

    const linkTable = 
      <table width="360" style={{  marginLeft:"auto",marginRight: "auto"}}>
      <tbody>
      <tr>
        <td align="left"><Link to="/disclaimer">Disclaimer</Link></td>
        <td align="center"><Link to="/privacy">Privacy Policy</Link></td>
        <td align="right"><Link to="/contactus">Contact Us</Link></td>
      </tr>
      </tbody>
      </table>;

   return (
    <Box container="footer" sx={{ display: 'flex' }}>
        <AppBar position="fixed" color="secondary" sx={{top: 'auto', bottom: 0, width: { sm: `calc(100% - ${drawerWidth}px)` }}}>
          <Toolbar >
            {linkTable}
          </Toolbar>
      </AppBar>
    </Box>
  );
};


export default Footer;