import React from "react";
import { Helmet } from "react-helmet";

const Header = ({location,pageTitle}) => {
  const title=pageTitle;
  return <Helmet
        title={title}
        link={[
          {
            rel: `canonical`,
            href: location
          }
        ]}

        meta={[
          {
            property: `og:title`,
            content: title,
          },
          {
            property: `og:type`,
            content: `website`,
          }
        ]}
      >
      
        <script async 
                src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7221946839680198"
                crossorigin="anonymous">
        </script>
        <script>
          {`(adsbygoogle = window.adsbygoogle || []).push({});`}
        </script>   
   
      </Helmet>;

};

export default Header;