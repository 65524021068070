import { createTheme } from '@mui/material/styles';

const cbbTheme = createTheme({
    palette: {
      primary: {
        light: '#334f77',
        main: '#001f3f',
        dark: '#000d1a',
        contrastText: '#ffffff',
      },
      secondary: {
        light: '#f5f5f5',
        main: '#C0C0C0',
        dark: '#8c8c8c',
        contrastText: '#000000',
      },
    },
    typography: {
      fontFamily: 'Roboto, sans-serif',
    },
    overrides: {
      MuiButton: {
        root: {
          // Example: setting the background color for buttons
          backgroundColor: '#001f3f',
          color: '#ffffff',
          '&:hover': {
            backgroundColor: '#000d1a',
          },
        },
      },
    },
  });


export default cbbTheme;